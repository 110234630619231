import PropTypes from 'prop-types';

// :: Lib helpers
import { getTestProps } from '../../../lib/helpers';
import { twMerge } from 'tailwind-merge';

const TypeCardRow = ({ title, icon, value, testId, additionalValueClass }) => {
  if (!title && !value) return false;

  return (
    <div
      className="flex items-center py-1.5
      text-indigo-950 dark:text-white"
      {...getTestProps(testId, 'container')}
    >
      {icon}
      {value && (
        <span
          className={twMerge('font-bold mr-1', additionalValueClass)}
          {...getTestProps(testId, 'value')}
        >
          {value}
        </span>
      )}
      {title && <span {...getTestProps(testId, 'title')}>{title}</span>}
    </div>
  );
};

export default TypeCardRow;

TypeCardRow.propTypes = {
  /**
   * Type card row title
   */
  title: PropTypes.string,
  /**
   * Type card row value
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  /**
   * Type card row test id
   */
  testId: PropTypes.string,
};

TypeCardRow.defaultProps = {
  title: '',
  value: '',
  testId: '',
  additionalValueClass: '',
};
