import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup';
import Tabs from '../../components/Tabs/Tabs';
import Basic from './tabs/Basic/Basic';
import Advanced, { MESSAGE_FIELDS } from './tabs/Advanced/Advanced';
import CancelButton from '../../components/Button/predefined/CancelButton/CancelButton';
import SaveButton from '../../components/Button/predefined/SaveButton/SaveButton';
import { ModalInstanceContext } from '../../contexts/ModalContext';
import Button from '../../components/Button/Button';
import { PencilIcon } from '../../images/shapes';
import FormScripts from './tabs/Scripts/Scripts';
import { getTestProps } from '../../lib/helpers';

const getConfig = (config) => {
  return typeof config === 'string' ? JSON.parse(config || '{}') : config || {};
};

const getInitialValues = (generatedForm) => ({
  origin: generatedForm?.origin || '*',
  flotiqApiKey: generatedForm?.flotiqApiKey || '',
  config: getConfig(generatedForm?.config),
  messages: getConfig(generatedForm?.messages),
});

const FormGeneratorModalForm = ({
  contentType,
  onSubmit,
  generateApiKey,
  generatedForm,
  apiKeys,
  testId,
}) => {
  const { t } = useTranslation();
  const modalInstance = useContext(ModalInstanceContext);

  const [isSaving, setIsSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(true);
  const [apiKeyGenerated, toggleApiKeyGenerated] = useReducer(
    (isGenerated) => !isGenerated,
    false,
  );

  useEffect(() => {
    if (generatedForm) {
      setIsEditing(false);
    }
  }, [generatedForm]);

  const isCloseModal = !generatedForm || !isEditing;

  const onCancel = useCallback(() => {
    if (!isCloseModal) setIsEditing(false);
    else modalInstance.resolve();
  }, [isCloseModal, modalInstance]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        origin: yup.string().required(t('Form.FormErrorNotBlank')),
        flotiqApiKey: yup.string().required(t('Form.FormErrorNotBlank')),
        config: yup.object().shape({
          recaptchaPublic: yup.string(),
          recaptchaPrivate: yup.string(),
          maxFiles: yup.number(),
          allowedTypes: yup.array().of(yup.string()),
        }),
        messages: yup
          .object()
          .shape(MESSAGE_FIELDS.reduce((field) => ({ [field]: yup.string() }))),
      }),
    [t],
  );

  const handleSubmit = useCallback(
    async (values, formik) => {
      setIsSaving(true);

      const [newValues, errors] = await onSubmit(values);
      if (!errors) {
        setIsEditing(false);
      } else {
        formik.setStatus({ ...formik.status, errors });
      }

      formik.resetForm({
        values: getInitialValues(newValues),
      });

      setIsSaving(false);
    },
    [onSubmit],
  );

  const onGenerateApiKey = useCallback(async () => {
    const apiKey = await generateApiKey();
    if (apiKey) toggleApiKeyGenerated();
    return apiKey;
  }, [generateApiKey]);

  const configTabs = useMemo(
    () => [
      {
        label: t('ContentTypeForm.FormGenerator.Basic'),
        key: 'basic',
        render: () => (
          <Basic
            apiKeys={apiKeys}
            onGenerateApiKey={onGenerateApiKey}
            apiKeyGenerated={apiKeyGenerated}
            contentType={contentType}
            testId={testId}
          />
        ),
      },
      {
        label: t('ContentTypeForm.FormGenerator.Advanced'),
        key: 'advanced',
        render: () => <Advanced testId={testId} />,
      },
    ],
    [apiKeyGenerated, apiKeys, contentType, onGenerateApiKey, t, testId],
  );

  const backToEdit = useCallback(() => {
    setIsEditing(true);
  }, []);

  return (
    <Formik
      initialValues={getInitialValues(generatedForm)}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnChange
      validateOnBlur
    >
      {(formik) => (
        <form
          id="generated-form"
          onSubmit={formik.handleSubmit}
          noValidate
          {...getTestProps(testId, 'form')}
        >
          {isEditing ? (
            <Tabs tabs={configTabs} testId={testId} />
          ) : (
            <FormScripts formUrl={generatedForm?.formUrl} testId={testId} />
          )}

          <div
            className="w-full fixed left-0 bottom-0 flex items-center justify-center p-3 space-x-5
            border-t border-gray dark:border-slate-800 bg-white dark:bg-gray-900"
          >
            <CancelButton
              onClick={onCancel}
              label={!isCloseModal ? t('Global.Cancel') : t('Global.Close')}
              {...getTestProps(testId, 'cancel', 'testId')}
            />

            {isEditing ? (
              <SaveButton
                form="generated-form"
                isSaving={isSaving}
                {...getTestProps(testId, 'save', 'testId')}
              />
            ) : (
              <Button
                buttonSize="sm"
                buttonColor="blue"
                onClick={backToEdit}
                iconImage={<PencilIcon className="w-3 min-w-3" />}
                iconPosition="start"
                {...getTestProps(testId, 'edit-settings', 'testId')}
              >
                {t('ContentTypeForm.FormGenerator.EditFormSettings')}
              </Button>
            )}
          </div>
        </form>
      )}
    </Formik>
  );
};

export default FormGeneratorModalForm;

FormGeneratorModalForm.propTypes = {
  /**
   * Content type for which form is displayed
   */
  contentType: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  /**
   * On form submit callback
   */
  onSubmit: PropTypes.func.isRequired,
  /**
   * On generate API key button click
   */
  generateApiKey: PropTypes.func.isRequired,
  /**
   * Generated form data
   */
  generatedForm: PropTypes.shape({
    origin: PropTypes.string,
    flotiqApiKey: PropTypes.string,
    config: PropTypes.string,
    messages: PropTypes.string,
  }),
  /**
   * User api keys
   */
  apiKeys: PropTypes.arrayOf(
    PropTypes.shape({
      apiKey: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      global: PropTypes.bool.isRequired,
    }),
  ),
  /**
   * Component test id
   */
  testId: PropTypes.string,
};

FormGeneratorModalForm.defaultProps = {
  apiKeys: [],
  testId: '',
};
