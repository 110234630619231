import { makeExternalServiceQuery } from './base-request';

export const getStarters = makeExternalServiceQuery(
  process.env.REACT_APP_FLOTIQ_STARTERS_URL,
  'GET',
  {
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Token': process.env.REACT_APP_FLOTIQ_STARTERS_X_AUTH_TOKEN,
    },
  },
);

export const getMarketplacePlugins = makeExternalServiceQuery(
  `${process.env.REACT_APP_MARKETPLACE_API_URL.replace(/\/+$/, '')}/manifests`,
  'GET',
);

export const getTutorial = makeExternalServiceQuery(
  process.env.REACT_APP_FLOTIQ_TUTORIAL_URL,
  'GET',
  {
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Token': process.env.REACT_APP_FLOTIQ_TUTORIAL_X_AUTH_TOKEN,
    },
  },
);

const generateFormQuery = makeExternalServiceQuery(
  process.env.REACT_APP_FORM_GENERATOR_URL,
  'POST',
  {},
  {},
  true,
);

export const generateForm = (jwt, body) =>
  generateFormQuery(jwt, undefined, undefined, { body: JSON.stringify(body) });
