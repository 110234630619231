import {
  useEffect,
  useContext,
  useCallback,
  useMemo,
  useState,
  useId,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { toast } from 'react-hot-toast';
import QRCode from 'react-qr-code';
import { Link } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import useLocalStorageState from 'use-local-storage-state';
import { useQueryClient } from '@tanstack/react-query';

// :: Components
import Heading from '../../components/Heading/Heading';
import Panel from '../../components/Panel/Panel';
import Loader from '../../components/Loader/Loader';
import Button from '../../components/Button/Button';
import Tooltip from '../../components/Tooltip/Tooltip';
import TopbarBreadcrumbs from '../../components/Topbar/breadcrumbs/TopbarBreadcrumbs';
import TopbarButton from '../../components/Topbar/buttons/base/TopbarButton';

// :: Components Inner
import ApiKeyItem from './APIKeyItem/APIKeyItem';

// :: Context
import { useModals } from '../../contexts/ModalContext';
import UserContext from '../../contexts/UserContext';

// :: Lib
import {
  getTestProps,
  generateApiDocs,
  parseScopedKeysDocs,
} from '../../lib/helpers';
import {
  deleteApiKey,
  regenerateApiKey,
  updateApiKey,
  postApiKey,
  listContentTypes,
} from '../../lib/flotiq-client';
import {
  ResponseError,
  checkResponseStatus,
} from '../../lib/flotiq-client/response-errors';

// :: Hooks
import { useApiKeys, useContentTypes } from '../../hooks/api';
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';
import useToken from '../../hooks/useToken';
import useSelectedSpace from '../../hooks/useSelectedSpace';

// :: Layout
import PageLayout, {
  predefinedLayoutClasses,
} from '../../layout/PageLayout/PageLayout';

// :: Icons
import { QuestionMarkIcon } from '../../images/shapes';

const APIKeys = ({ testId }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const modal = useModals();
  const jwt = useToken();
  const componentID = useId();
  const { space } = useSelectedSpace();
  const { planLimits } = useContext(UserContext);

  // :: Api Key Data
  const { data: apiKeysData, isLoading } = useApiKeys();
  const [currentApiKeys, setCurrentApiKeys] = useState([]);
  const [newUserApiKeysCounter, setNewUserApiKeysCounter] = useState(1);
  const [loading, setLoading] = useState();

  // :: User Data
  const [user] = useLocalStorageState('cms.user');

  // :: Limits
  const scopedKeysLimit = planLimits?.scoped_keys_limit;
  const scopedKeysDocs = parseScopedKeysDocs(planLimits?.scoped_keys_docs);

  const apiScopedLen = useMemo(() => {
    return currentApiKeys.filter((el) => !el.global).length;
  }, [currentApiKeys]);
  const disabledAddScopedKeys =
    scopedKeysLimit !== -1 && scopedKeysLimit <= apiScopedLen;

  // :: Content Type Data
  const ctdParams = useMemo(
    () => ({
      limit: 1000,
      order_by: 'label',
      order_direction: 'asc',
      page: 1,
    }),
    [],
  );

  const {
    data: contentTypes,
    isLoading: isLoadingCT,
    errors: ctdErrors,
  } = useContentTypes(ctdParams);

  const filterCtd = useCallback(
    async (query, _, setIsLoading) => {
      setIsLoading(true);
      let newOptions = [];
      try {
        const { body, status } = await listContentTypes(jwt, space, {
          ...ctdParams,
          name: query,
        });
        checkResponseStatus(body, status);
        newOptions = body.data || [];
      } catch (error) {
        toast.error(
          t(
            error instanceof ResponseError
              ? 'ContentForm.CouldntFetch'
              : 'Form.CommunicationErrorMessage',
          ),
        );
      }
      setIsLoading(false);
      return newOptions
        .filter((ctd) => !ctd.internal || ctd.name === '_media')
        .map((ctd) => ({ label: ctd.label, value: ctd.id }));
    },
    [jwt, ctdParams, t, space],
  );

  useEffect(() => {
    if (apiKeysData) {
      setCurrentApiKeys(apiKeysData);
    }
  }, [apiKeysData]);

  const contentTypesOptions = useMemo(() => {
    if (!contentTypes) return;

    return contentTypes
      .filter(
        (el) => !(el.internal && el.name !== '_tag' && el.name !== '_media'),
      )
      .map((el) => {
        return {
          value: el.id,
          label: el.label,
        };
      });
  }, [contentTypes]);

  useApiErrorsToast(ctdErrors);

  const handleAddApiKeys = () => {
    const newID = `${componentID}-${newUserApiKeysCounter}`;

    const newApi = {
      id: `_newid-${newID}`,
      name: t('ApiKeys.NewKey'),
      permissions: [
        {
          canRead: false,
          canUpdate: false,
          canCreate: false,
          canDelete: false,
          contentTypeDefinition: { id: '' },
        },
      ],
    };

    // Case: increment for unique id for add/delete newApiKey in one sesion
    setNewUserApiKeysCounter((prevState) => prevState + 1);

    setCurrentApiKeys((prevState) => [newApi, ...prevState]);
  };

  const handleGetSchema = useCallback(
    async (name, apiKey, generateSchema) => {
      if (generateSchema) {
        const result = await modal.confirmation(
          t('ApiKeys.GetScopedSchemaDescription', { name: name }),
          t('Global.Warning'),
          t('ApiKeys.GetScopedSchemaConfirm'),
          t('ApiKeys.GetScopedSchemaCancel'),
          'warning',
          { size: 'lg' },
        );
        if (result) {
          TagManager.dataLayer({
            dataLayer: {
              event: 'apidocs_visit',
            },
          });
          window.open(
            generateApiDocs(
              {
                organization: user?.data?.organization,
              },
              { apiKey },
            ),
            '_blank',
          );
        }
      } else {
        window.open(process.env.REACT_APP_PRICING, '_blank');
      }
    },
    [modal, user, t],
  );

  const handleRegenerate = useCallback(
    async (name, id) => {
      setLoading(id);
      const result = await modal.confirmation(
        t('ApiKeys.RegenerateDescription', { name: name }),
        t('Global.Warning'),
        t('ApiKeys.Regenerate'),
      );

      if (result) {
        try {
          const { body, status } = await regenerateApiKey(jwt, space, {
            id: id,
          });

          checkResponseStatus(body, status);

          setCurrentApiKeys((prevState) => {
            const apiIndex = prevState.findIndex((el) => el.id === id);

            prevState[apiIndex] = {
              ...prevState[apiIndex],
              apiKey: body.apiKey,
            };

            return prevState;
          });

          queryClient.invalidateQueries({ queryKey: ['api-keys'] });
          toast.success(t('ApiKeys.RegenerateSuccess', { name: name }));
        } catch (error) {
          if (!(error instanceof ResponseError)) {
            toast.error(t('ApiKeys.DeleteModalError'));
          } else {
            toast.error(
              error.message
                ? `${t('ApiKeys.DeleteModalError')}. ${error.message}`
                : t('ApiKeys.DeleteModalError'),
            );
          }
        }
      }
      setLoading();
    },
    [modal, t, jwt, space, queryClient],
  );

  const handleDelete = useCallback(
    async (id, name, mode) => {
      if (mode === 'unsaved') {
        setCurrentApiKeys((prevState) =>
          prevState.filter((el) => el.id !== id),
        );
        return;
      }

      const result = await modal.delete(
        t('ApiKeys.DeleteDescription', { name: name }),
      );

      if (result) {
        try {
          setLoading(id);

          const { body, status } = await deleteApiKey(jwt, space, {
            id: id,
          });
          checkResponseStatus(body, status);

          setCurrentApiKeys((prevState) =>
            prevState.filter((el) => el.id !== id),
          );

          queryClient.invalidateQueries({ queryKey: ['api-keys'] });
          toast.success(t('ApiKeys.DeleteModalSuccess'));
        } catch (error) {
          if (!(error instanceof ResponseError)) {
            toast.error(t('ApiKeys.DeleteModalError'));
          } else {
            toast.error(
              error.message
                ? `${t('ApiKeys.DeleteModalError')}. ${error.message}`
                : t('ApiKeys.DeleteModalError'),
            );
          }
        }
      }

      setLoading();
    },
    [modal, t, jwt, space, queryClient],
  );

  const handleShowQRCode = useCallback(
    (name, apiKey) => {
      modal.info(
        t('ApiKeys.YourApiKey', { name: name }),
        <div className="flex justify-center items-center pb-5">
          <QRCode
            size={300}
            value={`{"apiKey":"${apiKey}","apiUrl":"${process.env.REACT_APP_FLOTIQ_API_URL}/api"}`}
            viewBox={`0 0 300 300`}
          />
        </div>,
        'qr-modal-api-key',
        { size: 'lg' },
      );
    },
    [modal, t],
  );

  const handleSave = useCallback(
    async (id, name, apiKey, permissions) => {
      setLoading(id);

      const updateShape = {
        name: name,
        canCreate: false,
        canRead: false,
        canUpdate: false,
        canDelete: false,
        global: false,
        permissions: permissions,
      };

      if (apiKey) {
        try {
          const { body, status } = await updateApiKey(jwt, space, {
            id: id,
            ...updateShape,
          });
          checkResponseStatus(body, status);

          queryClient.invalidateQueries({ queryKey: ['api-keys'] });
          toast.success(t('ApiKeys.SaveNewApiKeySuccess', { name: name }));
          setLoading();
        } catch (error) {
          toast.error(t('ApiKeys.UpdateError'));
          setLoading();
        }
      } else {
        try {
          const { body, status } = await postApiKey(
            jwt,
            space,
            updateShape,
            undefined,
          );
          checkResponseStatus(body, status);
          setCurrentApiKeys((prevState) => {
            const apiIndex = prevState.findIndex((el) => el.id === id);
            prevState[apiIndex] = {
              ...prevState[apiIndex],
              ...body,
            };
            return prevState;
          });

          queryClient.invalidateQueries({ queryKey: ['api-keys'] });
          toast.success(t('ApiKeys.SaveNewApiKeySuccess', { name: name }));
          setLoading();
        } catch (error) {
          toast.error(t('ApiKeys.UpdateError'));
          setLoading();
        }
      }
    },
    [jwt, space, t, queryClient],
  );

  return (
    <PageLayout
      page="apiKeys"
      title={t('Global.APIKeys')}
      breadcrumbs={<TopbarBreadcrumbs />}
      buttons={
        <TopbarButton
          label={t('ApiKeys.AccessDocumentation')}
          link={process.env.REACT_APP_DOCUMENTATION_API}
          target="_blank"
          rel="noreferrer"
        />
      }
    >
      <div className={predefinedLayoutClasses.withSidebar}>
        <div className={predefinedLayoutClasses.leftColumn}>
          {isLoading || isLoadingCT ? (
            <div className="h-full overflow-hidden flex justify-center items-center">
              <Loader type="spinner-grid" size="big" />
            </div>
          ) : (
            <>
              <Heading
                level={3}
                additionalClasses="pt-0 pb-0 mb-4 text-xl lg:text-3xl leading-none dark:text-white"
              >
                {t('ApiKeys.AplicationApiKeys')}
              </Heading>
              {currentApiKeys?.map(
                (el) =>
                  el.global && (
                    <ApiKeyItem
                      key={el.id}
                      data={el}
                      rules={false}
                      generateSchema={true}
                      onGetSchema={handleGetSchema}
                      onRegenerate={handleRegenerate}
                      onDelete={handleDelete}
                      onShowQRCode={handleShowQRCode}
                      onSave={handleSave}
                      isLoading={el.id === loading}
                      {...getTestProps(testId, 'api-key-item', 'testId')}
                    />
                  ),
              )}
              <div className="my-4 border-t dark:border-slate-800 h-1 w-full" />
              <Heading
                level={3}
                additionalClasses="pt-0 pb-0 mb-4 text-xl lg:text-3xl leading-none dark:text-white"
              >
                {t('ApiKeys.UserDefinedApiKeys')}
              </Heading>
              <div className="flex flex-row items-center">
                <Button
                  buttonSize="sm"
                  onClick={handleAddApiKeys}
                  additionalClasses={'mb-2'}
                  disabled={disabledAddScopedKeys}
                  {...getTestProps(testId, `button-add-apikey`)}
                >
                  {t('ApiKeys.AddNewApiKey')}
                </Button>

                {disabledAddScopedKeys && (
                  <Link
                    to={process.env.REACT_APP_PRICING}
                    target="_blank"
                    rel="noreferrer"
                    className={'ml-3'}
                    {...getTestProps(testId, `disabled-scoped`)}
                  >
                    <Tooltip
                      tooltip={t('ApiKeys.Limits')}
                      tooltipPlacement={'topCenter'}
                    >
                      <div
                        className={twMerge(
                          'w-4 h-4 flex justify-center',
                          'items-center rounded-full bg-red',
                        )}
                      >
                        <QuestionMarkIcon className="w-2 h-2 text-white" />
                      </div>
                    </Tooltip>
                  </Link>
                )}
              </div>

              {currentApiKeys?.map(
                (el) =>
                  !el.global && (
                    <ApiKeyItem
                      key={el.id}
                      data={el}
                      options={contentTypesOptions}
                      optionsFilterCallback={filterCtd}
                      editableName={true}
                      removable={true}
                      generateSchema={scopedKeysDocs}
                      onGetSchema={handleGetSchema}
                      onRegenerate={handleRegenerate}
                      onDelete={handleDelete}
                      onShowQRCode={handleShowQRCode}
                      onSave={handleSave}
                      isLoading={el.id === loading}
                      {...getTestProps(testId, 'api-key-item', 'testId')}
                    />
                  ),
              )}
            </>
          )}
        </div>

        <div className={predefinedLayoutClasses.rightColumn}>
          <Panel
            title={t('ApiKeys.AplicationApiKeys')}
            isCollapsable={false}
            additionalContainerClasses="py-5 px-4"
            additionalTitleClasses="text-lg -mb-1"
            additionalChildrenClasses="dark:text-gray-200"
            open
          >
            <div className="flex flex-col space-y-3">
              {t('ApiKeys.AplicationApiKeysDescription')}
            </div>
          </Panel>

          <Panel
            title={t('ApiKeys.UserDefinedApiKeys')}
            isCollapsable={false}
            additionalContainerClasses="py-5 px-4"
            additionalTitleClasses="text-lg -mb-1"
            additionalChildrenClasses="dark:text-gray-200"
            open
          >
            <div className="flex flex-col space-y-3">
              {t('ApiKeys.UserDefinedApiKeysDescription')}
            </div>
          </Panel>
        </div>
      </div>
    </PageLayout>
  );
};

export default APIKeys;

APIKeys.propTypes = {
  /**
   * Test id for layout
   */
  testId: PropTypes.string,
};

APIKeys.defaultProps = {
  testId: '',
};
