import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { getTestProps } from '../../../../lib/helpers';

const FormScripts = ({ formUrl, testId }) => {
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(null);

  const scripts = useMemo(
    () => [
      {
        translationKey: 'Styles',
        script:
          '<link rel="stylesheet" ' +
          'href="https://flotiq-form-generator-styles.s3.amazonaws.com/flotiq-form-default.css"/>',
      },
      {
        translationKey: 'Form',
        script: '<flotiq-form/>',
      },
      {
        translationKey: 'Script',
        script: `<script src="${formUrl}" async></script>`,
      },
    ],
    [formUrl],
  );

  return (
    <div className="space-y-5">
      {scripts.map(({ translationKey, script }) => {
        return (
          <div key={translationKey}>
            <p className="block mb-2">
              {t(
                `ContentTypeForm.FormGenerator.ScriptDescription.${translationKey}`,
              )}
            </p>
            <CopyToClipboard
              className="block cursor-copy rounded-lg p-2 bg-gray-200 dark:bg-gray-600"
              text={script}
              onCopy={() => {
                setIsCopied(translationKey);
              }}
            >
              <code {...getTestProps(testId, `scripts-${translationKey}`)}>
                {script}
              </code>
            </CopyToClipboard>
            <span
              className="uppercase text-xs tracking-widest text-gray-600 dark:text-gray-400"
              {...getTestProps(testId, `scripts-${translationKey}-copy`)}
            >
              {isCopied === translationKey
                ? t('ContentTypeForm.FormGenerator.ScriptDescription.Copied')
                : t('ContentTypeForm.FormGenerator.ScriptDescription.Copy')}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default FormScripts;

FormScripts.propTypes = {
  /**
   * Generated form URL
   */
  formUrl: PropTypes.string,
  /**
   * Component test id
   */
  testId: PropTypes.string,
};

FormScripts.defaultProps = {
  testId: '',
};
