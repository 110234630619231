import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import TagManager from 'react-gtm-module';
import useLocalStorageState from 'use-local-storage-state';

// :: Contexts
import { useModals } from '../../contexts/ModalContext';
import SidebarContext from '../../contexts/SidebarContext';
import NewMediaContext from '../../contexts/NewMediaContext';
import UserContext from '../../contexts/UserContext';
import DirtyHandlerContext from '../../contexts/DirtyHandlerContext';

// :: Fetch
import { postContentType } from '../../lib/flotiq-client';

// :: Hooks
import { useContentObject, useContentType } from '../../hooks/api';
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';
import useToken from '../../hooks/useToken';
import useFirstLoading from '../../hooks/useFirstLoading';
import useSelectedSpace from '../../hooks/useSelectedSpace';
import { useGridNavigate } from '../../components/DataGrid/useGridFilters';

// :: Components
import Loader from '../../components/Loader/Loader';
import Heading from '../../components/Heading/Heading';
import ContentObjectInformations from '../../components/ContentObjectInformations/ContentObjectInformations';
import LinkButton from '../../components/LinkButton/LinkButton';
import Button from '../../components/Button/Button';
import FileButton from '../../components/FileButton/FileButton';
import LinkObjectContentModal from '../../components/RelationField/LinkObjectContentModal/LinkObjectContentModal';
import TopbarBreadcrumbs from '../../components/Topbar/breadcrumbs/TopbarBreadcrumbs';
import TopbarCancelButton from '../../components/Topbar/buttons/TopbarCancelButton';
import TopbarSaveButton from '../../components/Topbar/buttons/TopbarSaveButton';
import TopbarActionMenu from '../../components/Topbar/buttons/base/TopbarActionMenu';
import TopbarDuplicateButton from '../../components/Topbar/buttons/TopbarDuplicateButton';
import TopbarSaveAndLeaveButton from '../../components/Topbar/buttons/TopbarSaveAndLeaveButton';
import TopbarDeleteButton from '../../components/Topbar/buttons/TopbarDeleteButton';

// :: Helpers
import { getTestProps } from '../../lib/helpers';
import {
  getCtdFeaturedImage,
  markTaskAsDone,
} from '../../lib/flotiq-client/api-helpers';
import {
  ResponseError,
  checkResponseStatus,
} from '../../lib/flotiq-client/response-errors';

// :: Layout
import PageLayout, {
  predefinedLayoutClasses,
} from '../../layout/PageLayout/PageLayout';

// :: Icons
import { DeleteIcon, WarningIcon } from '../../images/shapes';

// :: Form
import ContentTypeForm from '../../form/ContentTypeForm/ContentTypeForm';

// :: Consts
import { DEFINED_TYPES } from '../ContentTypeDefinitions/definedTypes';
import FormGeneratorPanel from './formGenerator/FormGeneratorPanel';

const getFeaturedImage = (featuredImage) =>
  featuredImage?.id
    ? [
        {
          dataUrl: `/api/v1/content/_media/${featuredImage.id}`,
          type: 'internal',
        },
      ]
    : [];

const AddContentTypeDefinition = ({ duplicate, testId }) => {
  const { t } = useTranslation();
  const modal = useModals();
  const jwt = useToken();
  const navigate = useNavigate();
  const navigateOnSave = useRef();
  const { onUpload } = useContext(NewMediaContext);
  const { setDirty } = useContext(DirtyHandlerContext);
  const [user, setUser] = useLocalStorageState('cms.user');

  const { space, buildUrlWithSpace } = useSelectedSpace();
  const { reloadCtd } = useContext(SidebarContext);
  const { permissions } = useContext(UserContext);

  const [searchParams] = useSearchParams();
  const defaultType = searchParams.get('defaultType');

  const { contentTypeName } = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { canCreate, canDelete, canUpdate, canRead } = useMemo(
    () => permissions.getCtdPermissions(contentTypeName) || {},
    [contentTypeName, permissions],
  );

  const { gridLink } = useGridNavigate(
    `objects-${contentTypeName}`,
    buildUrlWithSpace(`content-type-objects/${contentTypeName}`),
  );

  const {
    entity: contentType,
    isLoading: contentTypeIsLoading,
    updateEntity: updateContenType,
    deleteEntity: deleteContentType,
    errors: contentTypeErrors,
    status: contentTypeStatus,
  } = useContentType(contentTypeName);

  const firstLoading = useFirstLoading(
    !contentTypeIsLoading || !contentTypeName,
    contentTypeName,
  );

  const definedType = useMemo(() => {
    if (!defaultType) return;
    return DEFINED_TYPES[defaultType]?.ctd;
  }, [defaultType]);

  const [currentFeaturedImage, setCurrentFeaturedImage] = useState([]);

  const { entity: featuredImage, errors: featuredImageErrors } =
    useContentObject(
      '_media',
      contentType?.featuredImage?.[0]?.dataUrl?.match(/[^/]+$/)?.[0],
    );

  useEffect(() => {
    if (featuredImage) setCurrentFeaturedImage(featuredImage);
  }, [featuredImage]);

  useApiErrorsToast(contentTypeErrors);
  useApiErrorsToast(featuredImageErrors);

  const handleDeleteType = useCallback(async () => {
    modal.deleting('delete-modal');
    try {
      const { body, status } = await deleteContentType({
        contentTypeName,
      });
      checkResponseStatus(body, status);
      toast.success(t('ContentDefinition.Deleted', { type: contentTypeName }));

      setDirty(false);
      navigate(buildUrlWithSpace('content-type-definitions'));
      reloadCtd();
    } catch (error) {
      if (!(error instanceof ResponseError)) {
        toast.error(t('Form.CommunicationErrorMessage'));
      } else {
        toast.error(
          error.message
            ? error.message
            : t('ContentDefinition.DeletingError', { type: contentTypeName }),
        );
        setIsSaving(false);
      }
    }
  }, [
    contentTypeName,
    buildUrlWithSpace,
    navigate,
    deleteContentType,
    modal,
    reloadCtd,
    t,
    setDirty,
  ]);

  const deleteType = useCallback(async () => {
    setIsDeleting(true);
    await modal.delete(
      t('ContentDefinition.ConfirmDelete'),
      'delete-modal',
      () => handleDeleteType(),
    );
    setIsDeleting(false);
  }, [handleDeleteType, modal, t]);

  const pageTitle = useMemo(() => {
    if (duplicate) return 'Duplicate';
    else if (contentTypeName) return 'Edit';
    return 'Add';
  }, [duplicate, contentTypeName]);

  const notAllowed = useMemo(
    () => contentType?.internal || (contentTypeName && !canRead),
    [contentType?.internal, contentTypeName, canRead],
  );

  const label = useMemo(
    () => contentType?.label || contentTypeName,
    [contentType?.label, contentTypeName],
  );

  const validateFeaturedImage = useCallback(
    (value) => {
      const mediaData = Array.isArray(value) ? value[0] : value;
      const isValid = !mediaData || mediaData.type === 'image';

      if (!isValid) toast.error(t('ContentTypeForm.FeaturedImage.Invalid'));
      return isValid;
    },
    [t],
  );

  const saveNewType = useCallback(
    async (values) => {
      setIsSaving(true);

      if (!validateFeaturedImage(currentFeaturedImage)) {
        setIsSaving(false);
        return [values, {}, true];
      }

      try {
        const { body, status } = await postContentType(jwt, space, {
          ...values,
          featuredImage: getFeaturedImage(currentFeaturedImage),
        });
        setIsSaving(false);
        checkResponseStatus(body, status);

        toast.success(
          t('ContentTypeForm.Added', { contentTypeName: body.label }),
        );
        setDirty(false);

        TagManager.dataLayer({
          dataLayer: {
            event: 'ctd_change',
          },
        });

        navigate(
          buildUrlWithSpace(`content-type-definitions/edit/${body.name}`),
        );

        markTaskAsDone('contentType', user, jwt, setUser, body.name);

        reloadCtd();
        return [body, {}, false];
      } catch (error) {
        setIsSaving(false);

        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
          return [values, {}, true];
        }

        if (error.status === 403 && error.message === 'ctd_limit') {
          toast.error(t('ContentDefinition.PlanExceeded'));
          return [values, error.errors, true];
        }

        toast.error(
          error.message
            ? t('ContentForm.Errors.TryAgain')
            : t('ContentTypeForm.AddingError'),
        );
        return [values, error.errors, true];
      }
    },
    [
      validateFeaturedImage,
      currentFeaturedImage,
      jwt,
      space,
      t,
      setDirty,
      navigate,
      buildUrlWithSpace,
      user,
      setUser,
      reloadCtd,
    ],
  );

  const updateType = useCallback(
    async (values) => {
      setIsSaving(true);

      if (!validateFeaturedImage(currentFeaturedImage)) {
        setIsSaving(false);
        return [values, {}, true];
      }

      try {
        const { status, body } = await updateContenType({
          ...values,
          featuredImage: getFeaturedImage(currentFeaturedImage),
          contentTypeName,
        });
        setIsSaving(false);
        checkResponseStatus(body, status);

        toast.success(
          t('ContentTypeForm.Saved', { contentTypeName: body.label }),
        );
        setDirty(false);

        TagManager.dataLayer({
          dataLayer: {
            event: 'ctd_change',
          },
        });

        reloadCtd();
        return [body, {}, false];
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
          setIsSaving(false);
          return [values, {}, true];
        }
        toast.error(
          error.message
            ? t('ContentForm.Errors.TryAgain')
            : t('ContentTypeForm.SavingError', { contentTypeName }),
        );
        setIsSaving(false);
        return [values, error.errors, true];
      }
    },
    [
      validateFeaturedImage,
      currentFeaturedImage,
      updateContenType,
      contentTypeName,
      t,
      reloadCtd,
      setDirty,
    ],
  );

  const openMediaModal = useCallback(async () => {
    const relation = await modal({
      title: (
        <div className="flex flex-wrap justify-between gap-1">
          <div className="text-3xl">{t('Global.MediaLibrary')}</div>
          <FileButton
            onUpload={onUpload}
            multiple
            additionalClasses="w-fit"
            testId={testId}
          />
        </div>
      ),
      content: (
        <LinkObjectContentModal
          relationType="_media"
          onMediaUpload={onUpload}
          returnContentObject
          validateSelected={(value) =>
            validateFeaturedImage(value ? Object.values(value)[0] : [])
          }
          defaultUserMediaFilters={{
            type: { filter: 'image', type: 'equals' },
          }}
          {...getTestProps(testId, 'link-media', 'testId')}
        />
      ),
      size: '2xl',
      dialogAdditionalClasses: 'h-[calc(100vh-32px)]',
    });

    const mediaData = relation ? Object.values(relation)[0] : [];
    if (mediaData) {
      setCurrentFeaturedImage(mediaData);
      setDirty(true);
    }
  }, [modal, t, onUpload, testId, validateFeaturedImage, setDirty]);

  const showForm = useMemo(
    () => !notAllowed && !firstLoading && (contentType || !contentTypeName),
    [notAllowed, firstLoading, contentType, contentTypeName],
  );

  const emptyCTD = useMemo(() => {
    if (showForm) return null;
    if (firstLoading) {
      return (
        <Loader
          size="small"
          type="spinner-grid"
          {...getTestProps(testId, 'loader', 'testId')}
        />
      );
    }
    if (notAllowed) {
      toast.error(
        t('ContentDefinition.CouldntFind', { contentTypeName: label }),
      );
    }
    return (
      <Heading
        level={2}
        additionalClasses={twMerge(
          'text-3xl md:text-4xl leading-8 dark:text-white',
        )}
      >
        <div
          className="flex flex-col items-center justify-center text-center"
          {...getTestProps(testId, 'empty-data')}
        >
          <WarningIcon
            className="text-red w-14 md:w-20 mb-3"
            title={t('ContentDefinition.CouldntFind')}
          />
          {contentTypeStatus === 404 || notAllowed
            ? t('ContentDefinition.CouldntFind', { contentTypeName })
            : t('ContentForm.CouldntFetch')}
        </div>
      </Heading>
    );
  }, [
    showForm,
    firstLoading,
    notAllowed,
    testId,
    t,
    contentTypeStatus,
    contentTypeName,
    label,
  ]);

  const finalContentType = useMemo(() => {
    if (defaultType) {
      return definedType;
    }
    if (duplicate && contentType) {
      return {
        label: contentType.label,
        name: contentType.name,
        metaDefinition: contentType.metaDefinition,
        schemaDefinition: contentType.schemaDefinition,
      };
    }
    return contentType;
  }, [contentType, definedType, defaultType, duplicate]);

  const isFormDisabled = useMemo(() => {
    if (isSaving || isDeleting) return true;
    if (contentTypeName) return !canUpdate;
    return !canCreate;
  }, [canCreate, canUpdate, contentTypeName, isDeleting, isSaving]);

  const deleteFeaturedImage = useCallback(() => {
    setCurrentFeaturedImage([]);
    setDirty(true);
  }, [setDirty]);

  const featuredImageUrl = getCtdFeaturedImage(
    contentType?.name,
    currentFeaturedImage,
    1000,
  );

  const isSaveButton =
    (canUpdate && contentTypeName) || (canCreate && !contentTypeName);

  return (
    <PageLayout
      page="definitionBuilder"
      title={t(`ContentTypeForm.${pageTitle}`, { contentTypeName: label })}
      buttonsDisabled={isSaving || isDeleting}
      breadcrumbs={
        <TopbarBreadcrumbs
          parentTitle={t('Global.DefinitionBuilder')}
          parentLink={buildUrlWithSpace('content-type-definitions')}
        />
      }
      buttons={
        <>
          <TopbarCancelButton
            link={buildUrlWithSpace('content-type-definitions')}
          />
          {isSaveButton && (
            <TopbarSaveButton
              form="ctd-form"
              isLoading={isSaving}
              navigateOnSave={navigateOnSave}
            />
          )}
          <TopbarActionMenu>
            {canCreate && contentTypeName && !duplicate && (
              <TopbarDuplicateButton
                link={buildUrlWithSpace(
                  `content-type-definitions/duplicate/${contentTypeName}`,
                )}
              />
            )}
            {isSaveButton && (
              <TopbarSaveAndLeaveButton
                form="ctd-form"
                navigateOnSave={navigateOnSave}
              />
            )}
            {canDelete && contentTypeName && !duplicate && (
              <TopbarDeleteButton onClick={deleteType} />
            )}
          </TopbarActionMenu>
        </>
      }
      testId={testId}
    >
      {showForm ? (
        <div className={predefinedLayoutClasses.withSidebar}>
          <div className={predefinedLayoutClasses.leftColumnWhite}>
            <ContentTypeForm
              key={pageTitle}
              contentType={finalContentType}
              isEditing={!!(contentTypeName && !duplicate)}
              onSubmit={
                contentTypeName && !duplicate ? updateType : saveNewType
              }
              disabled={isFormDisabled}
              navigateOnSave={navigateOnSave}
              onMediaUpload={onUpload}
              testId={testId}
            />
          </div>
          <div className={predefinedLayoutClasses.rightColumn}>
            <div className="mx-auto relative">
              <img
                key={featuredImageUrl}
                className="w-full rounded-lg object-cover aspect-[1.5]"
                src={featuredImageUrl}
                alt={`${contentType?.label || 'new-content-type'}`}
              />
              <div className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 flex gap-2">
                <Button
                  buttonSize="xs"
                  buttonColor="blueBordered"
                  onClick={openMediaModal}
                  additionalClasses={
                    'bg-white/70 dark:bg-white/70 dark:text-indigo-950 dark:disabled:hover:bg-white/70'
                  }
                  disabled={isFormDisabled}
                  {...getTestProps(testId, 'change-featured-image', 'testId')}
                >
                  {t('ContentTypeForm.FeaturedImage.Change')}
                </Button>
                <Button
                  buttonSize="xs"
                  buttonColor="redBordered"
                  onClick={deleteFeaturedImage}
                  iconImage={<DeleteIcon className="w-3" />}
                  additionalClasses={
                    'bg-white/70  dark:bg-white/70 dark:text-indigo-950 dark:disabled:hover:bg-white/70'
                  }
                  disabled={isFormDisabled}
                  {...getTestProps(testId, 'delete-featured-image', 'testId')}
                />
              </div>
            </div>
            <ContentObjectInformations
              createdAt={duplicate ? null : contentType?.createdAt}
              updatedAt={duplicate ? null : contentType?.updatedAt}
              testId={testId}
            >
              {!duplicate && contentType && permissions.canCo(contentTypeName) && (
                <LinkButton
                  link={gridLink}
                  buttonSize={'xs'}
                  additionalClasses="mb-2"
                  {...getTestProps(testId, 'goto-objects-list-btn')}
                >
                  {t('Global.GoToObjects')}
                </LinkButton>
              )}
            </ContentObjectInformations>

            {!duplicate && contentType && (
              <FormGeneratorPanel contentType={contentType} testId={testId} />
            )}
          </div>
        </div>
      ) : (
        <div className={predefinedLayoutClasses.whiteBox}>{emptyCTD}</div>
      )}
    </PageLayout>
  );
};

export default AddContentTypeDefinition;

AddContentTypeDefinition.propTypes = {
  /**
   * If type definition is duplicating
   */
  duplicate: PropTypes.bool,
  /**
   * Test id for page
   */
  testId: PropTypes.string,
};

AddContentTypeDefinition.defaultProps = {
  duplicate: false,
  testId: '',
};
