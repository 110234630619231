import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import Panel from '../../../components/Panel/Panel';
import Button from '../../../components/Button/Button';
import { useModals } from '../../../contexts/ModalContext';
import { getTestProps } from '../../../lib/helpers';
import FormGeneratorModal from './FormGeneratorModal';

const More = () => (
  <p>
    <Trans i18nKey="ContentTypeForm.FormGenerator.More">
      Currently, the form supports a limited list of fields. Learn more about
      generating and using the stand-alone forms
      <a
        className="text-blue"
        href={process.env.REACT_APP_DOCUMENTATION_FORM_GENERATOR}
        target="_blank"
        rel="noreferrer"
      >
        in the documentation
      </a>
    </Trans>
  </p>
);

const FormGeneratorPanel = ({ contentType, testId }) => {
  const { t } = useTranslation();
  const modal = useModals();

  const onGenerate = useCallback(async () => {
    await modal({
      title: t('ContentTypeForm.FormGenerator.Title'),
      content: (
        <div className="space-y-3">
          <More />
          <FormGeneratorModal
            contentType={contentType}
            {...getTestProps(testId, 'form-generator', 'testId')}
          />
        </div>
      ),
      size: 'xl',
    });
  }, [contentType, modal, t, testId]);

  return (
    <Panel
      title={t('ContentTypeForm.FormGenerator.Title')}
      isCollapsable={false}
      additionalContainerClasses="py-5 px-4"
      additionalTitleClasses="text-lg"
      additionalChildrenClasses="pt-2"
      open
    >
      <div className="space-y-4">
        <Trans i18nKey="ContentTypeForm.FormGenerator.Description" />
        <Button
          onClick={onGenerate}
          buttonSize="xs"
          {...getTestProps(testId, 'open-form-generator', 'testId')}
        >
          {t('ContentTypeForm.FormGenerator.Generate')}
        </Button>
        <More />
      </div>
    </Panel>
  );
};

export default FormGeneratorPanel;

FormGeneratorPanel.propTypes = {
  /**
   * Content type for which form is displayed
   */
  contentType: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  /**
   * Component test id
   */
  testId: PropTypes.string,
};

FormGeneratorPanel.defaultProps = {
  testId: '',
};
