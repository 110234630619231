import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Input from '../../../../components/Input/Input';
import { useFormikContext } from 'formik';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import { getTestProps } from '../../../../lib/helpers';
import useLocalStorageState from 'use-local-storage-state';
import { useMemo } from 'react';

export const MESSAGE_FIELDS = [
  'fieldIsInvalid',
  'fieldIsRequired',
  'chooseFiles',
  'sendText',
  'formIsNotConfiguredProperly',
  'formHasBeenSent',
  'chooseOptionText',
  'fileUploadInfo',
  'fileUploadDuplicateFileErrorMessage',
  'fileTooBig',
  'fileWrongFormat',
  'maxFilesReachedText',
  'maxUploadLimitReachedText',
];

const RECAPTCHA_FIELDS = ['recaptchaPublic', 'recaptchaPrivate'];

const AdvancedBox = ({ title, description, children, withDivider = true }) => (
  <>
    <p className="font-bold">{title}</p>
    <p>{description}</p>
    <div className="space-y-5 mt-5">{children}</div>
    {withDivider && (
      <div className="h-0 border-t border-slate-200  dark:border-slate-800 w-full my-10" />
    )}
  </>
);

const Advanced = ({ testId }) => {
  const { t, i18n } = useTranslation();
  const formik = useFormikContext();
  const [user] = useLocalStorageState('cms.user');

  const mimetypesOptions = useMemo(
    () =>
      user?.data?.allowedMimetypes?.split(',')?.map((value) => ({
        value: value,
        label: value,
      })) || [],
    [user?.data?.allowedMimetypes],
  );

  return (
    <>
      <AdvancedBox
        title={t('ContentTypeForm.FormGenerator.ReCaptcha')}
        description={t('ContentTypeForm.FormGenerator.ReCaptchaDescription')}
      >
        {RECAPTCHA_FIELDS.map((name) => (
          <Input
            key={name}
            name={`config.${name}`}
            label={t(`ContentTypeForm.FormGenerator.Field.${name}`)}
            value={formik.values.config?.[name] || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.status?.errors?.config?.[name] ||
              formik.errors.config?.[name]
            }
            {...getTestProps(testId, `recaptcha-${name}`, 'testId')}
          />
        ))}
      </AdvancedBox>

      <AdvancedBox
        title={t('ContentTypeForm.FormGenerator.FileUploads')}
        description={t('ContentTypeForm.FormGenerator.FileUploadsDescription')}
      >
        <Input
          name={`config.maxFiles`}
          label={t(`ContentTypeForm.FormGenerator.Field.maxFiles`)}
          value={formik.values.config?.maxFiles || 0}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.status?.errors?.config?.maxFiles ||
            formik.errors.config?.maxFiles
          }
          type="number"
        />

        <Dropdown
          name="config.allowedTypes"
          label={t('ContentTypeForm.FormGenerator.Field.allowedTypes')}
          value={formik.values.config?.allowedTypes || []}
          options={mimetypesOptions}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.status?.config?.allowedTypes ||
            formik.errors.config?.allowedTypes
          }
          helpText={t('ContentTypeForm.FormGenerator.HelpText.allowedTypes')}
          multiple
        />
      </AdvancedBox>

      <AdvancedBox
        title={t('ContentTypeForm.FormGenerator.Messages')}
        description={t('ContentTypeForm.FormGenerator.MessagesDescription')}
        withDivider={false}
      >
        {MESSAGE_FIELDS.map((name) => (
          <Input
            key={name}
            name={`messages.${name}`}
            label={t(`ContentTypeForm.FormGenerator.Field.${name}`)}
            value={formik.values.messages?.[name] || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.status?.errors?.messages?.[name] ||
              formik.errors.messages?.[name]
            }
            helpText={`${t('ContentTypeForm.FormGenerator.Default')}: ${
              i18n.exists(`ContentTypeForm.FormGenerator.HelpText.${name}`)
                ? t(`ContentTypeForm.FormGenerator.HelpText.${name}`)
                : '-'
            }`}
            {...getTestProps(testId, `messages-${name}`, 'testId')}
          />
        ))}
      </AdvancedBox>
    </>
  );
};

export default Advanced;

Advanced.propTypes = {
  /**
   * Component test id
   */
  testId: PropTypes.string,
};

Advanced.defaultProps = {
  testId: '',
};
