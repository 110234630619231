import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import Input from '../../../../components/Input/Input';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import Button from '../../../../components/Button/Button';
import { Link } from 'react-router-dom';
import useSelectedSpace from '../../../../hooks/useSelectedSpace';
import UserContext from '../../../../contexts/UserContext';
import { getTestProps } from '../../../../lib/helpers';

const Basic = ({
  apiKeys,
  onGenerateApiKey,
  apiKeyGenerated,
  contentType,
  testId,
}) => {
  const formik = useFormikContext();
  const { t } = useTranslation();
  const { space } = useSelectedSpace();
  const { planLimits } = useContext(UserContext);

  const [generatingKey, setGeneratingKey] = useState(false);

  const handleGenerate = useCallback(async () => {
    setGeneratingKey(true);
    const newKey = await onGenerateApiKey();

    if (newKey) {
      formik.setFieldValue('flotiqApiKey', newKey.apiKey);
    }
    setGeneratingKey(false);
  }, [formik, onGenerateApiKey]);

  const apiKeyOptions = useMemo(
    () => apiKeys.map(({ apiKey, name }) => ({ value: apiKey, label: name })),
    [apiKeys],
  );

  const defaultApiKey = useMemo(
    () =>
      apiKeys.find(({ permissions }) =>
        permissions?.find(
          ({ canCreate, canRead, contentTypeDefinition }) =>
            canCreate && canRead && contentTypeDefinition.id === contentType.id,
        ),
      )?.apiKey,
    [apiKeys, contentType.id],
  );

  useEffect(() => {
    if (defaultApiKey && !formik.values.flotiqApiKey) {
      formik.setFieldValue('flotiqApiKey', defaultApiKey);
    }
  }, [defaultApiKey, formik]);

  const disabledGenerateKey = useMemo(() => {
    const scopedKeysLen = apiKeys.filter((el) => !el.global).length;
    const scopedKeysLimit = planLimits?.scoped_keys_limit;
    return scopedKeysLimit !== -1 && scopedKeysLimit <= scopedKeysLen;
  }, [apiKeys, planLimits?.scoped_keys_limit]);

  return (
    <div className="space-y-5">
      <Input
        name="origin"
        label={t('ContentTypeForm.FormGenerator.Field.origin')}
        value={formik.values.origin}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.status?.errors?.origin || formik.errors.origin}
        helpText={t('ContentTypeForm.FormGenerator.HelpText.origin')}
        required
        {...getTestProps(testId, 'origin', 'testId')}
      />

      <Dropdown
        name="flotiqApiKey"
        label={t('ContentTypeForm.FormGenerator.Field.flotiqApiKey')}
        value={formik.values.flotiqApiKey}
        options={apiKeyOptions}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={
          formik.status?.errors?.flotiqApiKey || formik.errors.flotiqApiKey
        }
        helpText={
          <>
            {t('ContentTypeForm.FormGenerator.HelpText.flotiqApiKey')}
            <div className="flex items-center mt-2 gap-2">
              <Button
                onClick={handleGenerate}
                disabled={
                  apiKeyGenerated || generatingKey || disabledGenerateKey
                }
                buttonSize="xs"
                {...getTestProps(testId, 'generate-api-key', 'testId')}
              >
                {t('ContentTypeForm.FormGenerator.ApiKeyGenerate')}
              </Button>
              {apiKeyGenerated && t('ContentTypeForm.FormGenerator.Generated')}
              {disabledGenerateKey && (
                <span>
                  <Trans i18nKey="ContentTypeForm.FormGenerator.ApiKeyLimitReached">
                    You've reached maximum number of keys in your plan, please
                    select existing key, or
                    <Link className="text-blue" to={`/space/upgrade/${space}`}>
                      upgrade your plan
                    </Link>
                  </Trans>
                </span>
              )}
            </div>
          </>
        }
        disabled={generatingKey}
        additionalDropdownClasses="top-auto bottom-full"
        required
        {...getTestProps(testId, 'api-key', 'testId')}
      />
    </div>
  );
};

export default Basic;

Basic.propTypes = {
  /**
   * On generate button click callback
   */
  onGenerateApiKey: PropTypes.func.isRequired,
  /**
   * Content type for which form is displayed
   */
  contentType: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  /**
   * User api keys
   */
  apiKeys: PropTypes.arrayOf(
    PropTypes.shape({
      apiKey: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      global: PropTypes.bool.isRequired,
    }),
  ),
  /**
   * If scoped api key was generated
   */
  apiKeyGenerated: PropTypes.bool,
  /**
   * Component test id
   */
  testId: PropTypes.string,
};

Basic.defaultProps = {
  apiKeys: [],
  apiKeyGenerated: false,
  testId: '',
};
